import React, { useState,useEffect  } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Header from "../../components/Header";
import Form from 'react-bootstrap/Form';

import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import { tokens } from "../../theme";
import axios from "axios";
import { Box, useTheme } from "@mui/material"; 

function GroupExample() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userData, setUserData] = useState(null);
  const [posData, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);


  const fetchData = async () => {
    try {
      const response = await axios.get("https://prevision.dfi-linkhybpro.fr/Data/api/UserPreference/GetPerfermance");
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching position data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const fetchPositions = async () => {
    try {
      const response = await axios.get("https://prevision.dfi-linkhybpro.fr/Data/api/Positions");
      setPositions(response.data);
    } catch (error) {
      console.error("Error fetching position data:", error);
    }
  };

  useEffect(() => {
    fetchPositions();
  }, []);
  const handlePositionSelection = (position) => {
    setSelectedPosition(position);
  };



  const handleDeletePreference = (Id) => {
    axios
      .delete(`https://prevision.dfi-linkhybpro.fr/Data/api/UserPreference/${Id}`)
      .then((res) => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting position:", error);
      });
  };

  
  

  


console.log(userData);

  return (
    
    <Box m="20px">
      <Header title="MODELS" subtitle="Model's list" />
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
      {userData && userData.map((item, index) => (
        <Card key={index} sx={{ maxWidth: 345, margin: '20px 0 0 20px', backgroundColor: colors.grey[400]}}>
          <CardContent>
            <Typography gutterBottom variant="h2" component="div">
              {item.name}
            </Typography>
            <Typography gutterBottom variant="h3" component="div">
            {` Model :${item.model}  `}
            </Typography>

            <Typography gutterBottom variant="h3" component="div">
            {` Forecast Day :${item.forecastDay} days `}
            </Typography>
            <Typography gutterBottom variant="h3" component="div">
            {` Past Day :${item.pastDay} days `}
            </Typography>
            
            <Typography gutterBottom variant="h3" component="div">
            {` - Weather SHow : `}
            </Typography>

           

            <Typography variant="body2" color="text.secondary">
              {Object.entries(item).map(([key, value]) => {
                if (value === true) {
                  return <div key={key}>{key}</div>;
                }
                return null;
              })}
            </Typography>
          </CardContent>
          <CardActions>
         
      
          <Button  variant="outlined" startIcon={<DeleteIcon />} sx={{ backgroundColor: colors.redAccent[400] }}  onClick={() => {
           
              handleDeletePreference(item.id);
           
          }}
>
            Delete
          </Button>
          <Button variant="contained" sx={{ color: colors.greenAccent[400] }} endIcon={<SendIcon />} onClick={handleShow}>
            Select position
          </Button>
          
          </CardActions>
        </Card>
      ))}
      </div>
   

      {/* Fenêtre modale */}
      <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{ backgroundColor: colors.blueAccent[900], borderBottom: `1px solid ${colors.grey[700]}` }}>
          
          <Modal.Title style={{ color: colors.grey[100] }} >Positions</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: colors.blueAccent[800], color: colors.grey[100] }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Select a position:</Form.Label>
              {posData && posData.map((position) => (
                <Form.Check
                  key={position.Id}
                  type="radio"
                  label={`${position.lattitude} - ${position.longitude}`}
                  name="position"
                  id={`position-${position.Id}`}
                />
              ))}
            </Form.Group>
            {/* Other form fields */}
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: colors.blueAccent[900], borderTop: `1px solid ${colors.grey[700]}` }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Show Dashboard
          </Button>
        </Modal.Footer>
      </Modal>
      

   
    </Box>
    
  );
}

export default GroupExample;
