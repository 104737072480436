import { MapContainer,Marker,Popup,TileLayer} from "react-leaflet";
import "./Map.css";
import React, { useState } from "react";
import L from "leaflet";
import LeafletGeocoder from "./LeafletGeocoder";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import "leaflet-control-geocoder/dist/Control.Geocoder.css"


function Map({onPositionChange}) {
  const [position, setPosition] = useState([51.505, -0.09]);
    const handleClick = (e) => {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      onPositionChange({ latitude: lat, longitude: lng });
      console.log("Position:", { latitude: lat, longitude: lng });
    };
  
    return(
        <MapContainer center={position} zoom={13} scrollWheelZoom={false} onClick={handleClick}
        >
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
     <Marker position={position} />
   <LeafletGeocoder/>
  </MapContainer>

    );
}
let DefaultIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    iconSize: [25, 41]
  });
  L.Marker.prototype.options.icon = DefaultIcon;
  
export default Map;