import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import GroupExample from './scenes/form/out';
const Data = () => {
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://prevision.dfi-linkhybpro.fr/Data/api/Apiweather', {
                    params: {
                        Latitude: 33.874374,
                        Longitude: 10.855732,
                        Forecast_days: 1,
                        Past_days: 1,
                        cardmod: 'standard'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    }
                });
                setWeatherData(response.data);
            } catch (error) {
                console.error('Error fetching weather data:', error);
            }
        };

        fetchData();
    }, []);
    

    let chartData = [];
    let pieChartData = [];
    let dailyy = [];
   

    if (weatherData && weatherData.hourly && weatherData.hourly.time && weatherData.hourly.temperature_2m && weatherData.hourly.relative_humidity_2m && weatherData.hourly.wind_speed_10m) {
        chartData = weatherData.hourly.time.map((time, index) => ({
            time: time.split('T')[1].substring(0, 5), // Extraction de l'heure au format HH:mm
            temperature: weatherData.hourly.temperature_2m[index],
            humidity: weatherData.hourly.relative_humidity_2m[index],
            wind_speed_10m: weatherData.hourly.wind_speed_10m[index]

        }));
    }
    if (weatherData && weatherData.current) {
        
        pieChartData = [
            { id: "Temperature ",value: weatherData.current.temperature_2m },
            { id: "Humidity", value: weatherData.current.relative_humidity_2m },
            { id: "rain",value:weatherData.current.rain},
            { id: "snowfall",value:weatherData.current.snowfall},
            { id: "is_day",value:weatherData.current.is_day}

           
        ];
    }
    

    if (weatherData && weatherData.daily) {
        
        dailyy = [
            { id: "precipitation_sum",value: weatherData.daily.precipitation_sum },
            { id: "rain_sum", value: weatherData.daily.rain_sum },
            { id: "snowfall_sum",value:weatherData.daily.snowfall_sum},
            { id: "sunrise",value:weatherData.daily.sunrise},
            { id: "sunset",value:weatherData.daily.sunset},
            {id:"wind_gusts_10m_max",value:weatherData.daily.wind_gusts_10m_max},
            {id:"temperature_2m_min",value:weatherData.daily.temperature_2m_min},
            {id:"temperature_2m_max",value:weatherData.daily.temperature_2m_max},
            {id:"time",value:weatherData.daily.time}
            
        ];
    }

    return {chartData,pieChartData,dailyy};
};

export default Data;
