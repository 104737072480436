import React, { useState } from 'react';
import axios from 'axios';
import Header from "../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, Checkbox, TextField } from "@mui/material";
import { Formik } from "formik";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import * as yup from "yup";



function FormComponent() {
  
  
  const [formData, setFormData] = useState({
    showTemperature: false,
    showHumidity: false,
    showWind: false,
    showWindDirection_10m :false,
    showWindDirection_80m:false,
    showSoilTemperature_0cm : false,
    showWindSpeed_10m : false,
    showWindSpeed_80m: false,
    Precipitation:false,
    Sunset:false,
    Sunrise:false,
    selectedModel: '',
    selectedDay: '',
    selectedPastDay: '',
    selectedName:''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
    setFormData({
    showTemperature: false,
    showHumidity: false,
    showWind: false,
    showWindDirection_10m :false,
    showWindDirection_80m:false,
    showSoilTemperature_0cm : false,
    showWindSpeed_10m : false,
    showWindSpeed_80m: false,
    Precipitation:false,
    Sunset:false,
    Sunrise:false,
    selectedModel: '',
    selectedDay: '',
    selectedPastDay: '',
    selectedName:''
    });
  };

  
  const handlesave = () => {
  const { showTemperature, showHumidity, showWind,showWindDirection_10m,showWindDirection_80m,showSoilTemperature_0cm,showWindSpeed_10m,showWindSpeed_80m,Precipitation,Sunset,Sunrise,selectedModel,selectedDay,selectedPastDay,selectedName} = formData;
  
  const Data = {

    ReceiveTemperature:showTemperature,
    ReceiveHumidity:showHumidity,
    ReceiveWind:showWind,
    showWindDirection_10m:showWindDirection_10m,
    showWindDirection_80m:showWindDirection_80m,
    showSoilTemperature_0cm:showSoilTemperature_0cm ,
    showWindSpeed_10m:showWindSpeed_10m ,
    showWindSpeed_80m:showWindSpeed_80m,
    Precipitation:Precipitation,
    Sunset:Sunset,
    Sunrise:Sunrise,
    Model:selectedModel ,
    ForecastDay:selectedDay,
    PastDay:selectedPastDay,
    Name:selectedName

  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
    }
  const url = 'https://prevision.dfi-linkhybpro.fr/Data/api/UserPreference/PostPerfermance';
  
  axios.post(url, Data,config)
    .then((result) => {
      alert(result.data);
    })
    .catch((error) => {
      console.error(error);
    });
};



return (
  <Box m="20px" maxWidth="800px" mx="auto" p="20px" border="1px solid #ccc" borderRadius="8px">
    <Header title="CREATE MODEL" subtitle="Create a New MODEL" />

    
    <Formik
       onSubmit={handleSubmit}
    >
       {({ errors,touched }) => (
          <form onSubmit={handleSubmit}>

          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 3fr))"
          >
              <TextField
              fullWidth
              className="form-select"
              variant="filled"
              type="text"
              label="Name of Model"
              onChange={handleChange}
              value={formData.selectedName}  // Update this line
              name="selectedName"  // Update this line
              id="Name"
              error={!!touched.selectedName && !!errors.selectedName}  // Update this line
              helperText={touched.selectedName && errors.selectedName}  // Update this line
              sx={{ gridColumn: "span 4" }}
            />

             <label htmlFor="forecast_days" className="form-label">Forecast Days:</label>
            <select
               name="selectedDay"
               id="forecast_days"
              
               value={formData.selectedDay}
              onChange={handleChange}
             
              required
              className="form-select"
              variant="filled"
              sx={{ gridColumn: "span 2"}}
              error={!!touched.forecast_days&& !!errors.forecast_days}
                helperText={touched.forecast_days && errors.forecast_days}
            >
              <option value="">Select Forecast Days</option>
              {[...Array(16)].map((_, i) => (
                <option key={i + 1} value={i + 1}>{i + 1} Day{i + 1 > 1 ? 's' : ''}</option>
              ))}
            </select>

            <label htmlFor="past_days" className="form-label">Past Days:</label>

            <select
              name="selectedPastDay"
              id="past_days"
              value={formData.selectedPastDay}
              onChange={handleChange}
              required
              className="form-select"
             
              variant="filled"
              sx={{ gridColumn: "span 2" }}
              
            >
              <option value="">Select Past Days</option>
              {[...Array(16)].map((_, i) => (
                <option key={i + 1} value={i + 1}>{i + 1} Day{i + 1 > 1 ? 's' : ''}</option>
              ))}
            </select>
            <label className="form-label">Modèle:</label>
            <select
              name="selectedModel"
              
              value={formData.selectedModel}
              onChange={handleChange}
              required
              className="form-select"
              variant="filled"
              sx={{ gridColumn: "span 2" }}
              error={!!touched.Model&& !!errors.Model}
              helperText={touched.Model&& errors.Model}
            >
              <option value="">Sélectionner un modèle</option>
              <option value="">Choisissez un modèle</option>
              <option value="standard">Standard</option>
              <option value="Germany">Germany(8days max)</option>
              <option value="NOAA U.S">NOAA U.S(16days max)</option>
              <option value="ECMWF">ECMWF(10days max)</option>
              <option value="MeteoFrance">MeteoFrance(4days max)</option>
              <option value="JMA Japan">JMA Japan(11days max)</option>
              <option value="MET Norway">MET Norway(3days max)</option>

              <option value="GEM Canada">GEM Canada(10days max)</option>
              <option value="BOM Australia">BOM Australia(10days max)</option>
              <option value="CMA china">CMA china(10days max)</option>

            </select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showHumidity} 
                  onChange={handleChange} 
                  name="showHumidity" 
                />
              }
              label="Show Humidity" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showWindSpeed_10m} 
                  onChange={handleChange} 
                  name="showWindSpeed_10m" 
                />
              }
              label="showWindSpeed_10m" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.Sunrise} 
                  onChange={handleChange} 
                  name="Sunrise" 
                />
              }
              label="Sunrise"
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.Sunset} 
                  onChange={handleChange} 
                  name="Sunset" 
                />
              }
              label="Sunset" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.Precipitation} 
                  onChange={handleChange} 
                  name="Precipitation" 
                />
              }
              label="Precipitation" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showWindSpeed_80m} 
                  onChange={handleChange} 
                  name="showWindSpeed_80m" 
                />
              }
              label="showWindSpeed_80m" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showSoilTemperature_0cm} 
                  onChange={handleChange} 
                  name="showSoilTemperature_0cm" 
                />
              }
              label="showSoilTemperature_0cm" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showWindDirection_80m} 
                  onChange={handleChange} 
                  name="showWindDirection_80m" 
                />
              }
              label="showWindDirection_80m" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.WindDirection_10m} 
                  onChange={handleChange} 
                  name="WindDirection_10m" 
                />
              }
              label="WindDirection_10m" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showWind} 
                  onChange={handleChange} 
                  name="showWind" 
                />
              }
              label="showWind" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />
              <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showTemperature} 
                  onChange={handleChange} 
                  name="showTemperature" 
                />
              }
              label="showTemperature" 
              sx={{ gridColumn: "span 2" }}
              fullWidth
              variant="filled"
            />


          
          </Box>
          <Box display="flex" justifyContent="end" mt="80px">
            <Button type="submit" color="secondary" onClick={() => handlesave()} variant="contained">
              Create New Model
            </Button>
          </Box>
        </form>
      )}
    </Formik>

   

  </Box>
);
}



export default FormComponent;
