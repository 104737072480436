import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Checkbox, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";


const Model = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [formData, setFormData] = useState({
    showTemperature: false,
    showHumidity: false,
    showWind: false,
    showWindDirection_10m :false,
    showWindDirection_80m:false,
    showSoilTemperature_0cm : false,
    showWindSpeed_10m : false,
    showWindSpeed_80m: false,
    Precipitation:false,
    Sunset:false,
    Sunrise:false,
    selectedModel: '',
    selectedDay: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };

  const handleSubmit = (values) => {
    console.log(values);
  };

  const handlesave = () => {
    const { showTemperature, showHumidity, showWind,showWindDirection_10m,showWindDirection_80m,showSoilTemperature_0cm,showWindSpeed_10m,showWindSpeed_80m,Precipitation,Sunset,Sunrise,selectedModel,selectedDay} = formData;
    
    const Data = {
  
      ReceiveTemperature:showTemperature,
      ReceiveHumidity:showHumidity,
      ReceiveWind:showWind,
      showWindDirection_10m:showWindDirection_10m,
      showWindDirection_80m:showWindDirection_80m,
      showSoilTemperature_0cm:showSoilTemperature_0cm ,
      showWindSpeed_10m:showWindSpeed_10m ,
      showWindSpeed_80m:showWindSpeed_80m,
      Precipitation:Precipitation,
      Sunset:Sunset,
      Sunrise:Sunrise,
      Model:selectedModel ,
      ForecastDay:selectedDay
  
    };
    let config = {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
        }
      }
    const url = 'https://prevision.dfi-linkhybpro.fr/Data/api/UserPreference/PostPerfermance';
    
    axios.post(url, Data,config)
      .then((result) => {
        alert(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box m="20px">
      

      <Formik
        onSubmit={handleSubmit}
        initialValues={formData}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Forecast Days"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.selectedDay}
                name="selectedDay"
                error={!!touched.selectedDay && !!errors.selectedDay}
                helperText={touched.selectedDay && errors.selectedDay}
                sx={{ gridColumn: "span 2" }}
              />
              {/* Add more text fields as needed */}
              <Checkbox
                checked={values.showTemperature}
                onChange={handleChange}
                name="showTemperature"
                color="primary"
              />
              <Checkbox
                checked={values.showHumidity}
                onChange={handleChange}
                name="showHumidity"
                color="primary"
              />
              <Checkbox
                checked={values.showWind}
                onChange={handleChange}
                name="showWind"
                color="primary"
              />
              {/* Add more checkboxes as needed */}
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ gridColumn: "span 4" }}
              >
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  selectedDay: yup.string().required("Required"),
  // Add more validation as needed
});

export default Model;
