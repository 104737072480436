
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import  Data from "../Data";

const LineChart = ({ isCustomLineColors = false, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { chartData } = Data();

  return (

    <Line
    data={{
      labels: chartData.map((data) => data.time), 
      datasets: [
        {
          label: "Temperature",
          data: chartData.map((data) => data.temperature), 
          backgroundColor: "#064FF0",
          borderColor: "#064FF0",
        },
        {
          label: "Humidity",
          data: chartData.map((data) => data.humidity), 
          backgroundColor: "#FF3030",
          borderColor: "#FF3030",
        },
        {
          label: "wind_speed_10m",
          data: chartData.map((data) => data.wind_speed_10m), 
          backgroundColor: "#AD4F09",
          borderColor: "#AD4F09",
        },
      ],
    }}
    options={{
      elements: {
        line: {
          tension: 0.5,
        },
      },
      plugins: {
        title: {
          text: "Hourly Temperature & Humidity", 
        },
      },
    }}
    colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} 
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: "point" }}
  />
  
    
    
  );
};

export default LineChart;
