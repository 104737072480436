import { Route, Routes, Switch } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Dashboard from "./scenes/dashboard";
import Model from "./scenes/model";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FormComponent from "./scenes/form";
import SignIn from "./signup/SignIn";
import SignUp from "./signin/SignUp";
import Sidebar from "./scenes/global/Sidebar";
import Data from "./Data";

import Position from "./scenes/position/Position";
import Map from "./components/Map";
import Geography from "./scenes/geography";
import Positionslist from "./scenes/position/Positionslist";
import EditPosition from "./scenes/position/EditPosition";
import GroupExample from "./scenes/form/out";
import Basic from "./components/WeatherCard"
import { useNavigate } from 'react-router-dom';




import Team from "./scenes/team";


function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/Data" element={<Data />} />
              <Route path="/Model" element={<Model />} />
              <Route path="/line" element={<Line />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/form" element={<FormComponent />} />
              <Route path="/sign" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/map" element={<Map />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/position" element={<Position />} />
              <Route path="/PositionsList" element={<Positionslist />} />
              <Route path='PositionsList/edit/:id' component={<EditPosition/>} />
              
              <Route path="/team" element={<Team />} />
              <Route path="/out" element={<GroupExample />} />
              <Route path="/card" element={<Basic />} />

              
             

            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
