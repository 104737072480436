import { Box, Typography, useTheme,Button} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import Header from "../../components/Header";
import { useState, useEffect } from "react"; 
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [teamData, setTeamData] = useState([]); 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editUserData, setEditUserData] = useState({});
  const [id, idchange] = useState("");
  const [firstName,setName]=useState('');
  const [lastName,setlastName]=useState('');
  const[email,setEmail]=useState('');
  const[password,setPassword]=useState('');
  const [role, setRole] = useState(''); 


  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  
  const handleLastNameChange = (event) => {
    setlastName(event.target.value);
  };
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };





  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://prevision.dfi-linkhybpro.fr/Data/api/Users/${userId}`);
      // Mettre à jour les données de l'équipe après la suppression
      const updatedTeamData = teamData.filter((user) => user.id !== userId);
      setTeamData(updatedTeamData);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
 
  

  const handleEdit = (user) => {
    setEditUserData(user);

    setName(user.firstName || ''); 
    setlastName(user.lastName || ''); // Mettre à jour la valeur du nom de famille avec la valeur du champ "Last" de l'utilisateur
    setEmail(user.email || ''); // Mettre à jour la valeur de l'email avec la valeur du champ "email" de l'utilisateur
    setPassword(user.password || ''); // Mettre à jour la valeur du mot de passe avec la valeur du champ "password" de l'utilisateur

    setRole(user.role || '');
    handleShow(); 
  };
  
  const handleSave = async () => {
    try {
      const updatedUserData = {
        Id: id,
        Name: firstName,
        Last: lastName,
        Email: email,
        Password: password,
        Role:role,
        EmailConfirmed: 1
      };
    
      await axios.put(`https://prevision.dfi-linkhybpro.fr/Data/api/Users/${editUserData.id}`, updatedUserData);
  
      // Mettre à jour les données de l'équipe après la mise à jour
      const updatedTeamData = teamData.map((user) =>
        user.id === editUserData.id ? updatedUserData : user
      );
      setTeamData(updatedTeamData);
      
      handleClose(); // Ferme la boîte de dialogue de modification
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  
    

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://prevision.dfi-linkhybpro.fr/Data/api/Users");
        setTeamData(response.data); 
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchData(); 
  }, []); 
  const columns = [
    { field: "id", headerName: "Code User" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
        field: "last",
        headerName: "Last Name",
        flex: 1,
        cellClassName: "name-column--cell",
      },
    
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === "Admin"
                ? colors.greenAccent[600]
                : role === "User"
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {role === "Admin" && <AdminPanelSettingsOutlinedIcon />}
            
            {role === "User" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },

    


    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) => (
        <Box display="flex" alignItems="center">
          <Typography>{row.accessLevel}</Typography>
          <Button
            variant="outlined"
            onClick={() => handleDeleteUser(row.id)}
            startIcon={<DeleteIcon />}
            sx={{ color: colors.redAccent[400], ml: 1 }}
          >
            Delete
          </Button>
          <Button
            onClick={() => handleEdit(row)}
            variant="outlined"
            startIcon={<BorderColorIcon />}
            sx={{ color: colors.greenAccent[400], marginRight: "8px" }}
          >
            Edit
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="TEAM" subtitle="Managing the Team Members" />
      
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={teamData} columns={columns} />
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: colors.blueAccent[900], borderBottom: `1px solid ${colors.grey[700]}` }}>
          
        <Modal.Title style={{ color: colors.grey[100] }}>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: colors.blueAccent[800], color: colors.grey[100] }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Edit User </Form.Label>

            <Grid container spacing={2}>
           
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
            
                  value={firstName}
                  onChange={handleNameChange}
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
         
                  value={lastName}
                  onChange={handleLastNameChange}
                  autoComplete="family-name"
                />
              </Grid>


              <Grid item xs={12} >
                <TextField
                  required
                  select // Indicates that it's a select input
                  fullWidth
                  id="role"
                  label="Role"
                  value={role}
                 
                  onChange={handleRoleChange}
                  
                  
                >
                  <MenuItem value="Admin">Admin</MenuItem> {/* Option for Admin role */}
                  <MenuItem value="User">User</MenuItem> {/* Option for User role */}
                </TextField>
              </Grid>


              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  autoComplete="email"
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  autoComplete="new-password"
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item xs={12}>
                
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSave}
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>
           
         
          
            
                
              
            </Form.Group>
            {/* Other form fields */}
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: colors.blueAccent[900], borderTop: `1px solid ${colors.grey[700]}` }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
      </Box>
    </Box>
  );
};

export default Team;
