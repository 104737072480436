import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Map from "../../components/Map";



class Position extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        Lattitude: '',
        Longitude: '',
      }
  }
   getlocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.setState({ Lattitude: latitude, Longitude: longitude });
      }, (error) => {
        console.error('Error getting location:', error);

      });
    } else {
      console.error('Geolocation is not supported by this browser');
    }
  };
  
  Position = () => {
    axios.post('https://prevision.dfi-linkhybpro.fr/Data/api/Positions', { Lattitude: this.state.Lattitude, Longitude: this.state.Longitude })
        .then(json => {
            if (json) {
                alert("Data Saved Successfully");
                this.props.history.push('/PositionsList')
            }
            else {
                alert('Data not Saved');
                this.props.history.push('/PositionsList')
            }
        })
}
handleChange = (e) => {
  this.setState({ [e.target.name]: e.target.value });
}

render() {


  return (
    <Box m="20px">
      <Header title="CREATE Position" subtitle="Create a New User Position" />
      <Box display="flex" justifyContent="end" mt="40px">
      <Button type="button" color="secondary" onClick={this.getlocation} variant="contained">
          Get Your location 
      </Button>
        </Box>

      <Formik
       
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
       
        }) => (
          <form >
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Lattitude"
                onBlur={handleBlur}
                onChange={this.handleChange}
                value={this.state.Lattitude}
                name="Lattitude"
                error={!!touched.Lattitude && !!errors.Lattitude}
                helperText={touched.Lattitude && errors.Lattitude}
                sx={{ gridColumn: "span 4" }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Longitude"
                onBlur={handleBlur}
                onChange={this.handleChange}
                value={this.state.Longitude}
                name="Longitude"
                error={!!touched.Longitude && !!errors.Longitude}
                helperText={touched.Longitude && errors.Longitude}
                sx={{ gridColumn: "span 4" }}
              />
             

            </Box>
            
            <Box>
              
               <Map 
               />
               </Box>
             
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" onClick={this.Position} variant="contained">
                Create New Positon
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
}
export default Position;




