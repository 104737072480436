import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import LineChart from "../../components/LineChart";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import StatBox from "../../components/StatBox";
import EmailIcon from "@mui/icons-material/Email";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import  Data from "../../Data";
import GeographyChart from "../../components/GeographyChart";
import NightlightIcon from '@mui/icons-material/Nightlight';
import OpacityIcon from '@mui/icons-material/Opacity';
import AirIcon from '@mui/icons-material/Air';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';



const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { pieChartData } = Data();
    const rain = pieChartData.find(data => data.id === "rain")?.value;
    const snowfall = pieChartData.find(data => data.id === "snowfall")?.value;
    const relative_humidity_2m  = pieChartData.find(data => data.id === "Humidity")?.value;
    const temperature = pieChartData.find(data => data.id === "Temperature ")?.value;
    const is_day =pieChartData.find(data => data.id === "is_day")?.value;
    
   

  
    return (
   
      <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" otherSubtitle="Current Weather :" />   
        
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
          <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            subtitle= {<p>rain : {rain} mm </p>}
            progress="0.75"
            icon={
              <>
              <WaterDropIcon
                sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
              />
              <span style={{ marginLeft: '80px' }} /> 

              </>
            }
            
          />
           <StatBox

                subtitle={<p>snowfall : {snowfall} cm </p>}
                icon={
                  
                  <AcUnitIcon sx={{ color: colors.greenAccent[600], fontSize: "40px" }} />
                  }
            />
          
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            subtitle= {<p>Temperature : { temperature } °C </p>}
            progress="0.75"
            icon={
              <>
              <ThermostatIcon
                sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
              />
              <span style={{ marginLeft: '150px' }} /> 

              </>
            }
            
          />
           <StatBox

                subtitle={<p>Humididty : {relative_humidity_2m}% </p>}
                icon={
                  
                  <OpacityIcon sx={{ color: colors.greenAccent[600], fontSize: "40px" }} />
                  }
            />
          
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
           <StatBox
              subtitle= {
                <p>{is_day === 1 ? "is Day Now" : "is Night Now"}</p>
              }
              progress="0.75"
              icon={
                <>
                  {is_day === 1 ? (
                    <WbSunnyIcon sx={{ color: colors.greenAccent[600], fontSize: "40px" }} />
                  ) : (
                    <NightlightIcon sx={{ color: colors.greenAccent[600], fontSize: "40px" }} />
                  )}
                  <span style={{ marginLeft: '80px' }} /> 
                </>
              }
            />
           <StatBox

                subtitle={<p>Wind Speed: 8.9km/h </p>}
                icon={
                  
                  <AirIcon sx={{ color: colors.greenAccent[600], fontSize: "40px" }} />
                  }
            />
          
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            subtitle= {<p>Sunrise : 5:40 </p>}
            progress="0.75"
            icon={
              <>
              <Brightness5Icon
                sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
              />
              <span style={{ marginLeft: '80px' }} /> 

              </>
              
            }
            
          />
           <StatBox

                subtitle={<p>Sunset : 18:15 </p>}
                icon={
                  
                  <WbTwilightIcon sx={{ color: colors.greenAccent[600], fontSize: "40px" }} />
                  }
            />
          
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="800"
                color={colors.grey[100]}
              >
                
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Chart :
              </Typography>
            </Box>
          </Box>
          <Box height="550px" m="-1px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>

        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
            color={colors.greenAccent[500]}
          >
            Pie Chart :
          </Typography>
          <Box height="250px" mt="-20px">
            <PieChart isDashboard={true} />
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h3"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
            color={colors.greenAccent[500]}
          >
            Geography Chart :
          </Typography>
          <Box height="250px" mt="-20px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box>
        
      </Box>
    </Box>
    
     

        );
        };
    export default Dashboard;