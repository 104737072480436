import React, { useEffect, useState } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import { useMapEvents } from "react-leaflet";

const LeafletGeocoder = () => {
  const map = useMap();
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    // Créer le contrôle geocoder et ajouter un gestionnaire d'événements pour "markgeocode"
    L.Control.geocoder({
      defaultMarkGeocode: false,
    })
      .on("markgeocode", function (e) {
        const latlng = e.geocode.center;
        const newMarker = L.marker(latlng)
          .bindPopup(e.geocode.name)
          .addTo(map)
          .openPopup();
        map.fitBounds(e.geocode.bbox);
        setMarker(newMarker);
      })
      .addTo(map);
  }, [map]);

  function onMapClick(e){
    console.log(e.latlng)
    alert(e.latlng)
  }
  map.on('click',onMapClick);

  return null;
};

export default LeafletGeocoder;
