import React from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import Data from "../Data";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";

export default function Basic() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { dailyy } = Data();
  const sunset = dailyy.find(data => data.id === "sunset")?.value ;
 const cardsToShow = dailyy.filter(data => 
    data.id === "sunset"
  );


  for (let i = 0; i < dailyy.length; i++) {
   // console.log("ID:", dailyy[i].id);
   // console.log("Value:", dailyy[i].value);
   const len =dailyy[i].value.length;
  }



  
    console.log(cardsToShow);
  
  
  return (
    <Box sx={{ margin: "20px", overflowX: "auto" }}>
      <Header title="Line Chart" subtitle="Simple Line Chart" />

      <Box sx={{ height: "90vh" }}>
        <section className="vh-100">
          
          
        {cardsToShow.map((data, idx) => (
              <MDBContainer key={data} className="h-100">
                <MDBRow className="justify-content-center align-items-center h-100">
                  <MDBCol md="8" lg="6" xl="4">
                 
                    <MDBCard style={{ color: "#4B515D", borderRadius: "35px" }}>
                      <MDBCardBody className="p-4">
                        <div className="d-flex">
                          <MDBTypography tag="h6" className="flex-grow-1">
                            {data.value[0]}
                          </MDBTypography>
                          <MDBTypography tag="h6">{data.value[1]}</MDBTypography>
                        </div>

                        <div className="d-flex flex-column text-center mt-5 mb-4">
                          <MDBTypography
                            tag="h6"
                            className="display-4 mb-0 font-weight-bold"
                            style={{ color: "#1C2331" }}
                          >
                            {" "}
                            13°C{" "}
                          </MDBTypography>
                          <span className="small" style={{ color: "#868B94" }}>
                            Stormy
                          </span>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1" style={{ fontSize: '1rem' }}>
                            <div>
                              <MDBIcon
                                fas
                                icon="wind fa-fw"
                                style={{ color: "#868B94" }}
                              />{" "}
                              <span className="ms-1"> 40 km/h</span>
                            </div>
                            <div>
                              <MDBIcon
                                fas
                                icon="tint fa-fw"
                                style={{ color: "#868B94" }}
                              />{" "}
                              <span className="ms-1"> 84% </span>
                            </div>
                            <div>
                              <MDBIcon
                                fas
                                icon="sun fa-fw"
                                style={{ color: "#868B94" }}
                              />{" "}
                              <span className="ms-1"> 0.2h </span>
                            </div>
                          </div>
                          <div>
                            <img
                              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-weather/ilu1.webp"
                              alt="Weather Icon"
                              width="100px"
                            />
                          </div>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                    
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            ))}
        </section>
      </Box>
    </Box>
  );
}
