
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React,{Fragment,useState} from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';


function useSignUpNavigation() {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate('/sign');
  };

  return navigateToLogin;
}


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const defaultTheme = createTheme();

export default function SignUp() {

  const navigateToLogin = useSignUpNavigation();

  const [id, idchange] = useState("");
  const [firstName,setName]=useState('');
  const [lastName,setlastName]=useState('');
  const[Email,setEmail]=useState('');
  const[Password,setPassword]=useState('');
  const [role, setRole] = useState(''); 
  const navigate = useNavigate();

  const handleNameChange = (value) => {
    setName( value);

  }
  const handleLastNameChange = (value) => {
    setlastName( value);

  }

  const handleEmailChange = (value) => {
    setEmail( value);

  }
  const handlePasswordChange = (value) => {
    setPassword( value);

  }
  const handleRoleChange = (value) => {
    setRole( value);

  }

  const handleSave = () => {
    const data = {
      Id:id,
      Name: firstName,
      Last: lastName,
      Email: Email,
      Password: Password,
      Role:role,
      EmailConfirmed: 1
    };

    // Vérifier si l'e-mail est valide
    if (!isValidEmail(Email)) {
      alert("Invalid email format.");
      return;
    }

    // Vérifier si l'e-mail existe déjà dans la base de données
    axios.get(`https://prevision.dfi-linkhybpro.fr/Data/api/Users/checkEmail?email=${Email}`)
      .then((response) => {
        if (response.data.exists) {
          alert("User with this email already exists.");
        } else {
          // Envoyer les données à l'API pour l'enregistrement
          axios.post('https://prevision.dfi-linkhybpro.fr/Data/api/Users/register', data)
            .then((result) => {
              alert("User registered successfully!");
              navigateToLogin();
            })
            .catch((error) => {
              alert("Error registering user.");
            });
        }
      })
      .catch((error) => {
        alert("Error checking email.");
      });
  }

  const isValidEmail = (Email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(Email);
  }


  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={(e)=>handleNameChange(e.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={(e)=>handleLastNameChange(e.target.value)}
                  autoComplete="family-name"
                />
              </Grid>


              <Grid item xs={12} >
                <TextField
                  required
                  select // Indicates that it's a select input
                  fullWidth
                  id="role"
                  label="Role"
                  value={role} // Value of the selected role
                  onChange={(e)=>handleRoleChange(e.target.value)}
                  
                  
                >
                  <MenuItem value="Admin">Admin</MenuItem> {/* Option for Admin role */}
                  <MenuItem value="User">User</MenuItem> {/* Option for User role */}
                </TextField>
              </Grid>


              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e)=>handleEmailChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e)=>handlePasswordChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={()=> handleSave()}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
