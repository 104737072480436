import { Box } from "@mui/material";
import Header from "../../components/Header.jsx";
import LineChart from "../../components/LineChart";
import Data from "../../Data.js";

const Line = () => {
  const { chartData, pieChartData, weatherData } = Data();
 

  return (
    <Box sx={{ margin: "20px", overflowX: "auto" }}> 
      <Header title="Line Chart" subtitle="Simple Line Chart"/>
      
      <Box sx={{ height: "90vh" }}>
        {weatherData && weatherData.current && ( 
          <>
            <h2>
              Current Temperature: {weatherData.current.temperature_2m}°C, 
              Current Humidity: {weatherData.current.relative_humidity_2m}%, 
              Current Precipitation: {weatherData.current.rain} mm,
              Current Snowfall: {weatherData.current.snowfall} mm
            </h2>
          </>
        )}
        <LineChart />
      </Box>
    </Box>
  );
};

export default Line;
