import React, { Component } from 'react';
import axios from 'axios';

export default class EditPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: '',
            longitude: ''
        };
    }

    componentDidMount() {

        axios.get('https://prevision.dfi-linkhybpro.fr/Data/api/Positions/:id' )
            .then(response => {
                this.setState({
                    latitude: response.data.latitude,
                    longitude: response.data.longitude
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    onChangeLatitude = (e) => {
        this.setState({
            latitude: e.target.value
        });
    }

    onChangeLongitude = (e) => {
        this.setState({
            longitude: e.target.value
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const obj = {
            latitude: this.state.latitude,
            longitude: this.state.longitude
        };
        axios.put('https://prevision.dfi-linkhybpro.fr/Data/api/Positions/edit/:id', obj)
            .then(res => {
                console.log(res.data);
                this.props.history.push('/PositionsList');
            });
    }

    render() {
        return (
            <div style={{ marginTop: 10 }}>
                <h3>Edit Position</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>Latitude: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.latitude}
                            onChange={this.onChangeLatitude}
                        />
                    </div>
                    <div className="form-group">
                        <label>Longitude: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.longitude}
                            onChange={this.onChangeLongitude}
                        />
                    </div>
                    <div className="form-group">
                        <input type="submit" value="Update Position" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        )
    }
}
