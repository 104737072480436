import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import TextField from '@mui/material/TextField';
import axios from "axios";

const Positionslist = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [positionData, setPositionData] = useState([]);
  const [id, idchange] = useState("");
  const [latitude,setlatitude]=useState('');
  const [longitude,setlongitude]=useState('');
  const [editPositionData, setEditPositionData] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://prevision.dfi-linkhybpro.fr/Data/api/Positions");
      setPositionData(response.data);
    } catch (error) {
      console.error("Error fetching position data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlelatitudeChange = (event) => {
    setlatitude(event.target.value);
  };
  
  const handlelongitudeChange = (event) => {
    setlongitude(event.target.value);
  };

  const handleEdit = (position) => {
    setEditPositionData(position);

  
    setlatitude(position.setlatitude|| ''); 
    setlongitude(position.setlongitude || ''); // Mettre à jour la valeur du nom de famille avec la valeur du champ "Last" de l'utilisateur
    handleShow(); 
  };
  
  const handleSave = async () => {
    try {
      const updatedPositionDataRequest = {
        Id: id,
        Lattitude: latitude,
        Longitude: longitude
      };
      await axios.put(`https://prevision.dfi-linkhybpro.fr/Data/api/Positions/${editPositionData.id}`, updatedPositionDataRequest);
  
      const updatedData = positionData.map((position) =>
        position.id === editPositionData.id ? updatedPositionDataRequest : position
      );
      setPositionData(updatedData);
  
      handleClose(); // Close the edit modal
    } catch (error) {
      console.error("Error updating position:", error);
    }
  };
  
  


  const deletePosition = (positionId) => {
    axios
      .delete(`https://prevision.dfi-linkhybpro.fr/Data/api/Positions/delete?id=${positionId}`)
      .then((res) => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting position:", error);
      });
  };

  const columns = [
    
    {
      field: "lattitude",
      headerName: "lattitude",
      flex: 1,
    },
    {
      field: "longitude",
      headerName: "longitude",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => (
        <Box>
          <Button
            variant="outlined"
            startIcon={<BorderColorIcon />}
            sx={{ color: colors.greenAccent[400], marginRight: "8px" }}
            onClick={() => handleEdit(row)}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            onClick={() => deletePosition(row.id)}
            startIcon={<DeleteIcon />}
            sx={{ color: colors.redAccent[400] }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Positions" subtitle="PositionList" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={positionData}
          columns={columns}
          disableColumnMenu
          autoHeight
        />
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: colors.blueAccent[900], borderBottom: `1px solid ${colors.grey[700]}` }}>
          
        <Modal.Title style={{ color: colors.grey[100] }}>Edit position</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: colors.blueAccent[800], color: colors.grey[100] }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Edit Position </Form.Label>

            <Grid container spacing={2}>
           
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lattitude"
                  label="lattitude"
            
                  value={latitude}

                  onChange={handlelatitudeChange}
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="longitude"
                  label="longitude"
         
                  value={longitude}
                  onChange={handlelongitudeChange}
                  autoComplete="family-name"
                />
              </Grid>
              </Grid>
              <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSave}
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>
 
            </Form.Group>
            {/* Other form fields */}
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: colors.blueAccent[900], borderTop: `1px solid ${colors.grey[700]}` }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>

      </Box>
    </Box>
  );
};

export default Positionslist;
